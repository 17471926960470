<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col>
          <b-form-group label="Sosyal Ağ">
            <v-select v-model="form.baslik" :options="sosyal" :clearable="false" class="invoice-filter-select d-block" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider name="Url" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Url">
              <b-form-input
                class="rounded-0"
                ref="url"
                v-model="form.url"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
                placeholder="https://example.com"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Icon">
            <b-form-input class="rounded-0" ref="icon" v-model="form.icon" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-1">
            <label for="statu" class="font-weight-bold h6">{{ $t('hotspot.kullanimDurumu') }}</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button squared block type="submit" :variant="form._id == null ? 'primary' : 'warning'">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">{{ form._id == null ? $t('global.ekle') : $t('global.guncelle') }}</span>
          </b-button>
          <b-button squared block type="submit" v-if="form._id" @click="resetForm">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">{{ $t('global.iptal') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

import { ref, toRefs, watch } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';

export default {
  components: {
    vSelect,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();

    expo.sosyal = ref([
      'Facebook',
      'Instagram',
      'Twitter',
      'Google plus',
      'Behance',
      'Bitbucket',
      'Codepen',
      'Delicious',
      'Deviantart',
      'Dribbble',
      'Flickr',
      'Foursquare',
      'Github',
      'Jsfiddle',
      'Linkedin',
      'Medium',
      'Pinterest',
      'Reddit',
      'Stumbleupon',
      'Tumblr',
      'Twitch',
      'Vimeo',
      'Vine',
      'Vk',
      'Weibo',
    ]);
    expo.form = ref({
      _id: null,
      baslik: null,
      url: null,
      icon: null,
      statu: true,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.resetForm = () => {
      expo.form.value = {
        _id: null,
        baslik: null,
        url: null,
        icon: null,
        statu: true,
      };
      context.emit('refresh', {});
      context.emit('showLoading', false);
    };
    expo.onSubmit = () => {
      context.emit('showLoading', true);
      const basrUrl = expo.form.value._id == null ? 'sosyalMedyaEkle' : 'sosyalMedyaGuncelle';

      store
        .dispatch(basrUrl, expo.form.value)
        .then((res) => {
          if (res.data.success === true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );

            expo.resetForm();
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    let updateData = toRefs(props).updateData;
    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length > 0) expo.form.value = val;
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
