<template>
  <b-list-group class="rounded-0">
    <div v-for="item in data" :key="item._id">
      <b-list-group-item
        class="d-flex justify-content-between align-items-center p-1 mb-25"
        :class="item.statu ? ' border-primary' : ' border-danger'"
      >
        <h6 class="mt-25">{{ item.baslik }}</h6>
        <div>
          <b-button-group>
            <b-button
              squared
              size="sm"
              :variant="item.statu ? 'success' : 'danger'"
              v-b-tooltip.hover.top="item.statu ? 'Aktif' : 'Pasif'"
            >
              <feather-icon icon="EyeIcon" v-if="item.statu" />
              <feather-icon icon="EyeOffIcon" v-else />
            </b-button>
            <b-button squared size="sm" variant="warning" v-b-tooltip.hover.top="'Güncelle'" @click="$emit('update', item)">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button squared size="sm" variant="danger" v-b-tooltip.hover.top="'Sil'" @click="handlerRemove(item._id)">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-button-group>
        </div>
      </b-list-group-item>
    </div>
  </b-list-group>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import Swal from 'sweetalert2';
export default {
  components: {
    BCardNormal,
  },
  props: {
    data: {
      type: Array,
      required: false,
    },
  },
  setup(props) {
    const expo = {};
    const toast = useToast();

    expo.handlerRemove = (_id) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('sosyalMedyaSil', _id).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Bilgi`,
                    icon: 'CheckSquareIcon',
                    variant: 'success',
                    text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
            }
          });
        }
      });
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
