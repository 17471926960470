<template>
  <b-row>
    <b-col md="4">
      <b-card-normal title="Sosyal Medya Tanımlama" :showLoading="show">
        <template v-slot:body>
          <tanimlama @showLoading="show = $event" :updateData="updateData" @refresh="updateData = $event" />
        </template>
      </b-card-normal>
    </b-col>
    <b-col md="8" order-sm="12">
      <b-card-normal title="Sosyal Medya Listesi" :showLoading="show">
        <template v-slot:body>
          <list :data="rows" @update="handlerUpdate($event)" />
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import Tanimlama from './component/tanimla.vue';
import List from './component/listele.vue';

import { ref, onMounted, computed } from '@vue/composition-api';
import store from '@/store';

export default {
  components: {
    BCardNormal,
    Tanimlama,
    List,
  },
  setup() {
    const expo = {};

    expo.show = ref(false);
    expo.rows = ref([]);
    expo.updateData = ref({});

    onMounted(async () => {
      expo.show.value = true;
      await store.dispatch('sosyalMedyaListele');
      expo.show.value = false;
    });

    expo.rows = computed(() => store.getters.getSosyalMedyaListele);

    expo.handlerUpdate = (event) => {
      expo.updateData.value = event;
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
